import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const BztPage = () => (
    <Layout>
        <Seo 
            title="Game Studio website design and Webflow development" 
            description="Case study of the new website designed, and built on Webflow, for Bazooka Tango."
        />

        <Hero
            h1="Game Studio website design case study"  
            h2="Design &amp; Development of the new Bazooka Tango website"
        />

        
        <section className="bg-noise bzt section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/bzt-main.png"
                        alt="Screens from the Bazooka Tango website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Bazooka Tango is a US-based game studio who are currently developing the highly anticipated multiplayer collectible tactics game Shardbound.</p>
                                    <p>The company needed a new, highly visual, website .</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Having previously completed some development work on the Shardbound website, Launch Lab were asked to design the new website and build it on Webflow.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Web design</Link>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the Bazooka Tango website.</p>
                                    <a href="https://www.bazookatango.com/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                    <div className="work-jumbo-wrapper with-radius">
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/portfolio/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                            View all projects
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default BztPage